/*** IMPORTS FROM imports-loader ***/
var define = false;

import { Power2 } from "gsap";

export default {
    init: (app, TweenMax, Vivus, ScrollMagic, DrawSVGPlugin) => {

        const $svg = $('#left path, .right-50 path');
        console.log($svg)
        const tlSvg = []
        $svg.each(function () {
            var timeline = new TimelineMax({ paused: true })
            timeline.set($(this), { drawSVG: '0%' })
            timeline.to($(this), 15, { drawSVG: '100%' })
            tlSvg.push(timeline)
        })

        tlSvg.map(element => {
            return element.play()
        })

        //APPARITIONS
        var controller = new ScrollMagic.Controller({
            globalSceneOptions: {
                triggerHook: '.7',
            }
        });

        var tl = new TimelineMax();
        tl.from($('.van'), 1, { x: "-150%" })

        new ScrollMagic.Scene({
            triggerElement: $('.win'),
            duration: $('.win').height()
        })
            .setTween(tl)
            .addTo(controller);



        //MAP
        const $mapLinked = $('.square-map, .point-map');

        $mapLinked.on('mouseenter', function () {
            let pin = $(this).find('.inner-text-map');
            TweenLite.to(pin, .2, { autoAlpha: 1 })
        })
        $mapLinked.on('mouseleave', function () {
            let pin = $(this).find('.inner-text-map');
            TweenLite.to(pin, .2, { autoAlpha: 0 })
        })



        //boxes
        const $boxes = $('.box');
        $boxes.each(function () {
            const $box = $(this);

            let bg = $box.find('.item-bg')
            let title = $box.find('.box-inner .item-title');
            let text = $box.find('.box-inner-text');
            let $svg = $box.find('.svg-container');

            var tl = new TimelineMax({ paused: true });
            tl.to(title, .6, { autoAlpha: 0, y: 15, ease: Power1.easeOut }, 'start')
            tl.to(bg, .6, { backgroundColor: '#593837', ease: Power1.easeOut }, 'start')
            tl.to($svg.find('svg path'), .3, { fill: '#77473C', ease: Power1.easeOut }, 'start')
            tl.to($svg, .4, { scale: 2.2, y: 35, ease: Power1.easeOut }, '-=0.4')
            tl.from(text, .4, { autoAlpha: 0, y: 15, ease: Power1.easeOut }, '-=0.4')



            $box.on('mouseenter', () => {
                tl.play()
            }).on('mouseleave', () => {
                tl.reverse()
            })

        })
    }
}

