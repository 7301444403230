/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs 
|------------------
*/
require('@lib/iziModal/js/iziModal.js')($); //désolé
import Swiper from 'swiper/dist/js/swiper.min';
//import { TweenMax } from 'gsap';
import { TweenMax } from "gsap/TweenMax";
import SplitText from "@lib/gsap-pro/SplitText";
import ScrollTo from "gsap/ScrollToPlugin";
import DrawSVGPlugin from "@lib/gsap-pro/DrawSVGPlugin";
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap.js';
import 'scrollmagic/scrollmagic/uncompressed/plugins/jquery.ScrollMagic.js';
import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';
import ScrollMagic from 'scrollmagic';
import Scrollbar from 'smooth-scrollbar';
import fullpage from 'fullpage.js';
import Plyr from 'Plyr';
import Vivus from 'Vivus';
import InfiniteAjaxScroll from "@lib/infinite-ajax-scroll/jquery-ias.min.js";
import Hammer from 'hammerjs';


console.log(Hammer);

/*
|
| Importing Components
|-----------------------
*/
import CookieManager from './components/cookie-manager';
import pagepiling from './components/pagepiling.js';
import customGoogleMap from './components/custom-google-map.js';
import Kira from './components/kira.js';
import MobileDetect from './components/mobile-detect.js';
import Menu from './components/menu.js';


/*
|
| Importing Utils
|-------------------
*/
//import './utils/fa';
import Router from './utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import * as app from './components/global.js';
import main from './pages/main.js';
import home from './pages/home.js';
import usStory from './pages/notre-histoire.js';
import usCheese from './pages/nos-fromages.js';
import usRecettes from './pages/nos-recettes.js';
import contact from './pages/contact.js';
import years from './pages/50ans.js';
import recetteSingle from './pages/recette.js'
import rse from './pages/rse.js'

/*
|
| Routing
|----------
*/

const routes = new Router([
    {
        'file': main,
        'dependencies': [app, CookieManager, Kira, ScrollMagic, Swiper, Menu, MobileDetect]
    },
    {
        'file': home,
        'dependencies': [app, pagepiling, Plyr, Swiper],
        'resolve': '#page-home'
    },
    {
        'file': usStory,
        'dependencies': [app, TweenMax, Swiper, Hammer],
        'resolve': '#page-us-story'
    },
    {
        'file': usCheese,
        'dependencies': [app, ScrollMagic],
        'resolve': '#page-fromages-archive'
    },
    {
        'file': years,
        'dependencies': [app, TweenMax, Vivus, ScrollMagic, DrawSVGPlugin],
        'resolve': '#page-50-years'
    },
    {
        'file': usRecettes,
        'dependencies': [app, InfiniteAjaxScroll, Swiper],
        'resolve': '#page-recettes-archive'
    },
    {
        'file': contact,
        'dependencies': [app, customGoogleMap],
        'resolve': '#page-contact'
    },
    {
        'file': rse,
        'dependencies': [app],
        'resolve': '#page-rse'
    },
    {
        'file': recetteSingle,
        'dependencies': [app, ScrollMagic],
        'resolve': '#page-recettes-single'
    }

]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

