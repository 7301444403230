/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, pagepiling, Plyr, Swiper) => {
        app.dump('home.js');

        /*
        |
        | Constants
        |-----------
        */
        const $loadedPostsContainer = $('.loaded-posts');
        const $swiperHome = $('.swiper-home');
        const $swiperHomeContainer = $swiperHome.find('.swiper-container');
        const swiperHome = new Swiper($swiperHomeContainer, {
            slidesPerView: 1,
            autoplay: {
                delay: 700,
            },
            effect: 'fade',
            speed: 500,

        });

        const player = new Plyr('#player');


        $(".item-scroll-down").click(function () {
            $('html, body').animate({
                scrollTop: $(".section1").offset().top
            }, 800);
        });


        /* 
        |
        | Ajax Sample
        |--------------
        */
        $('[data-ajax-url]').on('click', function () {
            $.ajax({
                url: $(this).data('ajax-url'),
                type: 'GET',
                dataType: 'json',
                success: (response, status) => {
                    $loadedPostsContainer.append(response);
                },
                error: (response, status, error) => {
                    console.log(response, status, error);
                }
            })
        });





        /*
        |
        | Menu Homepage
        |----------------
        */
        const link = $('.hoverable-menu');
        const randomStart = [-22, 5, -8];
        const textMenu = $('.menu-title');

        /*
        |
        | SplitText
        |--------------
        */
        var splitMenu = new SplitText(textMenu, {
            type: "words, chars",
            charsClass: "char",
        });

        $('.char').each(function () {
            let randomY = Math.floor(Math.random() * 10) - 10;
            console.log();
            $(this).css({
                transform: "translateY(" + randomY + "px)"
            })
        })


        $('.desktop-images-container').each(function (item) {
            let images = $(this).find('.desktop-images-menu')
            for (let index = 0; index < images.length; index++) {
                const element = images[index];
                element.style.transform = "rotate(" + randomStart[index] + "deg)"
            }
        });


        link.on('mouseenter', function () {
            let $this = $(this);
            let images = $this.find('.desktop-images-menu');

            [...images].forEach(function (image, i) {

                let randomX = Math.floor(Math.random() * 110) + 80;
                let randomY = Math.floor(Math.random() * 50) - 50;
                let randomR = Math.floor(Math.random() * 6) - 6;

                ((i == 1) ? randomX = -randomX : '');
                ((i == 2) ? (randomX = 0, randomY = 0) : '');

                var tl = new TimelineLite();
                tl.to(image, .1, { x: randomX, y: randomY, rotation: randomR, ease: Power4.easeIn })
            });
        });


        link.on('mouseleave', function () {
            let $this = $(this);
            let images = $this.find('.desktop-images-menu');

            [...images].forEach(function (image, i) {
                var tl = new TimelineLite();
                tl.to(image, .1, { x: 0, rotation: randomStart[i], y: 0 })
            });
        });
    }
}

