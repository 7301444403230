/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, ScrollMagic) => {
		$('.goto').on('click', function(event) {
			let target = '#'+$(this).data('href');
            let header_height = $('header').outerHeight();
            $('.list-name li').removeClass('active')

            $(this).addClass('active')

            //TweenMax.to(window, .5, {scrollTo: target});
            
            $('html, body').animate({
                scrollTop: $(target).offset().top
            }, 600);
        })
        
        const fromageSection = $('.fromages-content');

        var controller = new ScrollMagic.Controller({
            globalSceneOptions: {
                triggerHook: '.9',
            }
        });
        
        if ($(window).width() > 992) {
            fromageSection.each(function () {
                console.log(this)
                var left = $(this).find('.left')
                var right = $(this).find('.right')

                var tl = new TimelineMax();
                tl.to(left, 1, { x: "150px" }, 'start')
                tl.to(right, 1, { x: "-150px" }, 'start')

                new ScrollMagic.Scene({
                    triggerElement: this,
                    duration: $(this).height()
                })
                    .setTween(tl)
                    .addTo(controller)
            })
        }


	}
}

