/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, TweenMax, Swiper, Hammer) => {

		/*
			*   -----------------------------------------
			*   GLOBAL => Variables
			*   -----------------------------------------   
			*   @tweenmax : tl => Object
			*   -   -   -   -   -   -   -   -   -   -   - 
			*   @slider   : slides  => NodesElements
			*							  current => Int
			*							  left    => Element
			*							  right   => Element
			*							  right   => Element
			*							  animate => Boolean
			*   -----------------------------------------
		*/

   

		const tl = new TimelineLite();
		
        const sliderWrapper = document.querySelector('.slider-wrapper');

		const slides = document.querySelectorAll('.wrapper-slide');
		let current = 0;
		const left = document.querySelector('.navigation-left');
		const right = document.querySelector('.navigation-right');
		let index = [];
		for (let i=slides.length - 1; i >= 0; i--) {
			index.push(i);
		}
		let animate = false;

		/*
			*   -----------------------------------------
			*   INITIALIZE => Function
			*   -----------------------------------------
			*   @params : null
			*   -   -   -   -   -   -   -   -   -   -   - 
			*   @about  : Initialize all the page and add
			*		a click listener to launch slide. 
			*   -----------------------------------------
		*/

		function initilize() {
			for (let i = 0; i < slides.length; i++) {
				if (i !== 0) {
					if (i%2 === 0) {
						slides[i].querySelector('figure').style.transform = `rotate(${i * 2.5}deg)`	
					} else {
						slides[i].querySelector('figure').style.transform = `rotate(${i * -2.5}deg)`	
					}
				}
            }
            
            let hammer = new Hammer(sliderWrapper);

            hammer.on('swipeleft', () => {
                slide(-1)
            })

            hammer.on('swiperight', () => {
                slide(1)
            })


			left.addEventListener('click', () => { slide(-1) });
			right.addEventListener('click', () => { slide(1) });
		}

		/*
			*   -----------------------------------------
			*   SLIDE => Function
			*   -----------------------------------------
			*   @params   : direction => -1 or 1
			*   -   -   -   -   -   -   -   -   -   -   -    
			*   @about   : ...
			*   -----------------------------------------
		*/

		function slide(direction) {
			if (!animate) {
				animate = true;
				if (direction > 0) {
					TweenMax.to(slides[current].querySelector('figure'), .5, {
						x: `${slides[current].querySelector('figure').clientWidth}px`,
						ease: Power1.easeIn,
						onComplete: () => {
							for (let p of slides[current].querySelectorAll('p')) {
								p.style.opacity = 0;
								p.style.visibility = 'hidden';
							}
	
							let data = index[slides.length - 1];
							index.splice(slides.length - 1, 1);
							index.unshift(data);
	
							for	(let i = 0; i < slides.length; i++) {
								slides[i].style.zIndex = index[i]
							}
	
							TweenMax.to(slides[current].querySelector('figure'), 0.5, {
								x: 0,
								ease: Power1.easeOut,
							});
		
							current += direction;
							
							if (current < 0) {
								current = slides.length-1
							} else if (current >= slides.length) {
								current = 0
							}
		
							for (let slide of slides) {
								slide.classList.remove('current');
								if (slides[current] === slide) {
									slide.classList.add('current');
								}
							}
							let p = slides[current].querySelectorAll('p');
							TweenMax.to(p[0], 0.5, {
								autoAlpha: 0.25,
							});
							TweenMax.to(p[1], 0.5, {
								autoAlpha: 1,
							});
							animate = false;
						}
					})
				} else {
					tl.staggerTo(slides[current].querySelectorAll('p'), 0.25, {
						autoAlpha: 0,
					})
	
					current += direction;
							
					if (current < 0) {
						current = slides.length-1
					} else if (current >= slides.length) {
						current = 0
					}
	
					for (let slide of slides) {
						slide.classList.remove('current');
						if (slides[current] === slide) {
							slide.classList.add('current');
						}
					}
	
					tl.to(slides[current].querySelector('figure'), .5, {
						x: `-${slides[current].querySelector('figure').clientWidth}px`,
						ease: Power1.easeIn,
						onComplete: () => {
							let data = index[0];
							index.splice(0, 1);
							index.push(data);
	
							for	(let i = 0; i < slides.length; i++) {
								slides[i].style.zIndex = index[i]
							}
							TweenMax.to(slides[current].querySelector('figure'), 0.5, {
								x: 0,
								ease: Power1.easeOut
							});
							let p = slides[current].querySelectorAll('p');
							TweenMax.to(p[0], 0.5, {
								autoAlpha: 0.25,
							});
							TweenMax.to(p[1], 0.5, {
								autoAlpha: 1,
							});
							animate = false;
						}
					})
				}
			}
		}

		let swiper = new Swiper('.swiper-container', {
			slidesPerView: 4,
            spaceBetween: 30,
			navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
			},
			breakpoints: {
					1250: {
							slidesPerView: 3,
							spaceBetween: 30,
					},
					1025: {
							slidesPerView: 2,
							spaceBetween: 30,
					},
					812.5: {
							slidesPerView: 1,
							spaceBetween: 30,
					}
			}
    });

		initilize();

	}
}

