/*** IMPORTS FROM imports-loader ***/
var define = false;

import { TimelineMax } from "gsap";

export default {
	init: (app, ias, Swiper) => {


		let postsType = {
			situation: [],
			types: []
		};
		let url = `/ajax/recettes/filters/${postsType}`;
		let postsPerPage = 6;
		let numberOfAllPosts = $('.inspirations-recettes .card-recettes').data('posts');
		//
		// Filter situations or types
        //
        

		$('.categories-recettes a, .types-recettes a').on('click', function(e){
            // Stop propagation
            e.preventDefault();

                // Get parent of this
                var parent = $(this).parent().parent().parent()

                if ($(this).hasClass('filtered')) {

                    // Remove class filtered
                    $(this).removeClass('filtered');
                    // Remove element to all filters
                    if (parent.hasClass('categories-recettes')) {
                        postsType.situation = postsType.situation.filter(el => el !== $(this).data('filter'));
                    } else {
                        postsType.types = postsType.types.filter(el => el !== $(this).data('filter'));
                    }
                } else {
                    // Add class filtered
                    $(this).addClass('filtered');
                    // Add element to all filters
                    if (parent.hasClass('categories-recettes')) {
                        postsType.situation.push($(this).data('filter'));
                    } else {
                        postsType.types.push($(this).data('filter'));
                    }

                }
            


			// Check if is already filtered

			// Writte route with all arguments
			url = `/ajax/recettes/filters/${postsType}`;
			// Launch ajax raquest
			$.ajax({
				url: url,
				type: 'GET',
				method: 'GET',
				data: {
					'posts_type': postsType,
					'offset': 0
				},
				dataType: 'json',
				success: (response, status) => {
					if (postsPerPage >= numberOfAllPosts) {
						$('#pagination').hide();
					} else {
						$('#pagination').show();
                    }

                    $('#card').html(response).show();

                    const cardRecette = $('.card-recette, .single-details-recette');
                    const notes = {
                        1: 'Facile',
                        2: 'Moyen',
                        3: 'Difficile'
                    }
                    cardRecette.each(function () {
                        const bells = $(this).find('.bells');
                        const level = $(this).find('.level-recette');
                        const number = bells.data('bell');
                        const singleBell = bells.find('.bell');

                        for (let index = 0; index < number; index++) {
                            singleBell[index].style.opacity = 1;
                        }

                        level.text(notes[number]);
                    })

                    const tl = new TimelineMax()

                    tl
                        .to(window, 0.6, { scrollTo: $('#inspirations-recettes').offset().top - $('#header').outerHeight(), ease: Power1.easeOut })
                        .staggerFrom($('#card').find('.card-recette'), 0.8, { autoAlpha: 0, y: 30, ease: Power1.easeOut }, 0.1, '-=0.5');

                },
				error: (response, status, error) => {
					console.log(error);
				}
			});

			return false;
		})
		//
		// Load more posts
		//
		$('#pagination').on('click', function(e){
			e.preventDefault();

			let offset = $('#card .card-recette').length;
            console.log(offset)
			$.ajax({
				url: url,
				type: 'GET',
				method: 'GET',
				data: {
					'posts_type': postsType,
					'offset': offset
				},
				dataType: 'json',
				success: (response, status) => {
					if ((offset + postsPerPage) >= numberOfAllPosts) {
						$('#pagination').hide();
                    }
                                        
                    $('#card').append(response).show();
                    
                            const cardRecette = $('.card-recette, .single-details-recette');
                            const notes = {
                                1: 'Facile',
                                2: 'Moyen',
                                3: 'Difficile'
                            }
                            cardRecette.each(function () {
                                const bells = $(this).find('.bells');
                                const level = $(this).find('.level-recette');
                                const number = bells.data('bell');
                                const singleBell = bells.find('.bell');

                                for (let index = 0; index < number; index++) {
                                    singleBell[index].style.opacity = 1;
                                }

                                level.text(notes[number]);
                            })

                    const tl = new TimelineMax({
                        onComplete: function(){
                            $('#card').find('.card-recette').removeClass('loaded');
                        }
                    })

                    tl
                        //.to(window, 0.6, { scrollTo: $('#inspirations-recettes').offset().top - $('#header').outerHeight(), ease: Power1.easeOut })
                        .staggerFrom($('#card').find('.card-recette.loaded'), 0.8, { autoAlpha: 0, y: 30, ease: Power1.easeOut }, 0.1, 'start');
				},
				error: (response, status, error) => {
					console.log(error);
				}
			});

			return false;
		})
	}
}

