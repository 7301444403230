/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {



        // anchors
        jQuery('.anchor a[href*="#"]:not([href="#"])').click(function () {
            let offset = -60; // <-- change the value here
            if (
                location.pathname.replace(/^\//, "") ==
                this.pathname.replace(/^\//, "") &&
                location.hostname == this.hostname
            ) {
                let target = jQuery(this.hash);
                target = target.length
                    ? target
                    : jQuery("[name=" + this.hash.slice(1) + "]");
                if (target.length) {
                    jQuery("html, body").animate(
                        {
                            scrollTop: target.offset().top + offset,
                        },
                        1000
                    );
                    return false;
                }
            }
        });

    }
}
