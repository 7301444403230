/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, CookieManager, Kira, ScrollMagic, Swiper, Menu, MobileDetect) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $cookieBanner = $('.cookie-banner'),
            $header = $('.header'),
            $affectedByMobile = $('body, .site-container'),
            $cookieClose = $cookieBanner.find('.item-close'),
            $cookieAccept = $cookieBanner.find('.item-accept'),
            $cookieRefuse = $cookieBanner.find('.item-refuse'),
            scrollAreas = document.querySelectorAll('.scroll-area'),
            $menuContainer = document.getElementById('menu-container'),
            $menuWrapper = $('#menu-container'),
            $menuFromage = $menuWrapper.find('.fromage'),
            $menuUl = $menuWrapper.find('.swiper-menu'),
            $menuLi = $menuUl.find('.swiper-slide'),
            $menuLinks = $menuLi.find('a'),
            $menuBtn = $header.find('.btn-menu'),
            $menuBtnBars = $menuBtn.find('.item-burger'),
            $menuBtnBar = $menuBtnBars.find('> span'),
            $headerLogo = $('.header-logo')
            ;


        /*
           |
           | Kira
           |-------
           */
        const kira = new Kira(ScrollMagic, {
            debug: false,
            loadEvent: {
                domElement: $('body'),
                eventName: 'loaded'
            },
            optimize: true,
            options: {
                start: '-=0.6',
                triggerHook: 'onEnter'
            }
        });

        /*
        | fadeInUp.parallax.sm
        |-----------------------
        */
        kira.add('fadeInUp.parallax.sm', ($item, timeline, start) => {
            timeline.from($item, 0.8, {
                y: 100,
                autoAlpha: 0,
                ease: Sine.easeOut
            }, start)
        });

        /*
        | fadeInUp.parallax
        |--------------------
        */
        kira.add('fadeInUp.parallax', ($item, timeline, start) => {
            timeline.from($item, 1, {
                y: 40,
                autoAlpha: 0,
                ease: Power3.easeOut
            }, start)
        });

        /*
        | fadeInUp.Title
        |--------------------
        */
        kira.add('fadeInUp.title', ($item, timeline, start) => {
            var mySplitText = new SplitText($item, {
                type: "words"
            });
            var chars = mySplitText.words;
            timeline.staggerFrom(chars, .6, {
                y: 40,
                autoAlpha: 0,
                ease: Power3.easeOut
            }, 0.03)
        });

        kira.init();


        /*
        |
        | Swiper posts
        |---------------
        */
        const
            $swiperPosts = $('.swiper-posts'),
            $swiperPostsContainer = $swiperPosts.find('.swiper-container');



        if ($swiperPosts.length) {
            let swiperPost = new Swiper($swiperPostsContainer, {
                slidesPerView: 3,
                spaceBetween: 30,
                breakpoints: {
                    992: {
                        slidesPerView: 2,
                    },
                    768: {
                        slidesPerView: 1,
                    }
                }
            });
        }



        /*
        |
        | Variables
        |------------
        */
        let
            isMobile = null;


        /*
        |
        | Mobile Detect
        |----------------
        */
        let mobileDetector = new MobileDetect();
        isMobile = mobileDetector.isMobile();
        if (isMobile == null) {
            $affectedByMobile.addClass('isDesktop');
        } else {
            $affectedByMobile.addClass('isMobile');
        }


        /*
        |
        | Scroll Areas
        |---------------
        */
        Array.prototype.forEach.call(scrollAreas, element => {
            Scrollbar.init(element);
        });


        /*
        |
        | Cookie Manager
        |-----------------
        */
        // new CookieManager($cookieBanner, {
        //     name: 'adveris_cookie_use',
        //     duration: 30,
        //     buttons: {
        //         accept: $cookieAccept,
        //         refuse: $cookieRefuse,
        //         close: $cookieClose
        //     }
        // });


        /*
        |
        | Fake Loader
        |-----------
        */
        setTimeout(() => {
            $('.fake-loader').fadeOut("slow");
            app.dispachEvent($('body'), 'loaded');
        }, 500);

        /*
        |
        | Top page
        |-----------
        */
        $('.scrolltop').click(function () {
            $('html, body').animate({ scrollTop: 0 }, 'slow');
            return false;
        });
        $(window).on('scroll', function () {
            if ($(this).scrollTop() > 500) {
                $('.scrolltop').show(200)
            } else {
                $('.scrolltop').hide(200)
            }
        })

        /*
        |
        | SWIPER RECETTES
        |----------------
        */
        var numberSlide = 3;
        var simulateTouch = false;
        if (isMobile || $(window).width() < 992) {
            numberSlide = 1;
            simulateTouch = true;
        }

        var swiperRecettes = new Swiper('.swiper-recettes ', {
            speed: 400,
            slidesPerView: numberSlide,
            spaceBetween: 30,
            simulateTouch: simulateTouch,
            breakpointsInverse: false,
            pagination: {
                el: '.recettes-pagination',
                type: 'bullets',
            },
            breakpoints: {
                992: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                    simulateTouch: true,
                },
            }
        })


        /*
        |
        | Bells Recettes
        |----------------
        */
        const cardRecette = $('.card-recette, .single-details-recette');
        const notes = {
            1: 'Facile',
            2: 'Moyen',
            3: 'Difficile'
        }
        cardRecette.each(function () {
            const bells = $(this).find('.bells');
            const level = $(this).find('.level-recette');
            const number = bells.data('bell');
            const singleBell = bells.find('.bell');

            for (let index = 0; index < number; index++) {
                singleBell[index].style.opacity = 1;
            }

            level.text(notes[number]);
        })



        /*
        |
        | Header
        |---------
        */
        const $heade = $('#header');

        $(document).on('scroll', function () {
            if ($(".header-container").offset().top > 50) {
                $heade.addClass('sticky');
            } else {
                $heade.removeClass('sticky');
            }


        })



        /*
        |
        | Menu
        |-------
        */
        var swiperMenu = new Swiper('.swiper-menu', {
            speed: 400,
            centeredSlides: true,
            slidesPerView: 2,
            spaceBetween: 30,
            on: {
                touchStart: function () {
                    $('.alert').hide();
                },
            }
        });

        const menu = new Menu($menuWrapper, $menuBtn, { reverseTimeScale: 1.5 });

        menu.menuTimeline
            .to($menuBtnBar.eq(1), 0.3, { autoAlpha: 0 }, 'start')
            .to($menuBtnBar.eq(0), 0.5, { x: 0, y: 8, backgroundColor: '#ffffff', rotation: 45, ease: Power1.easeOut }, 'start')
            .to($menuBtnBar.eq(2), 0.5, { x: 0, y: -8, backgroundColor: '#ffffff', rotation: -45, ease: Power1.easeOut }, 'start')

            .to($menuContainer, .2, { autoAlpha: 1 })
            .from($menuWrapper, 0.5, { autoAlpha: 0, ease: Power4.easeOut }, 'start+=0.3')
            .staggerFrom($menuFromage, 0.6, { autoAlpha: 0, x: 50, ease: Power4.easeOut }, 'start+=0.3')
            .staggerFrom($menuLi, 0.6, { autoAlpha: 0, x: 50, ease: Power4.easeOut }, '0.1')

        menu.init();

        $menuWrapper.on('menu:open', () => {
            $menuWrapper.addClass('opened');
            $header.addClass('header-menu-open');
            $("body").css({
                "overflow": "hidden"
            })
        });

        $menuWrapper.on('menu:closed', () => {
            $menuWrapper.removeClass('opened')
            $header.removeClass('header-menu-open');
            $("body").css({
                "overflow": "scroll"
            })
        });


        /*
        |
        | Anmation
        |----------------
        */
        const $split_text = $(".split-text");
        const $image_anim = $('.anim-image');

        var controlup = new ScrollMagic.Controller({
            globalSceneOptions: {
                triggerHook: '.9',
            }
        })

        var controlmiddle = new ScrollMagic.Controller({
            globalSceneOptions: {
                triggerHook: '1',
            }
        })

        var controldown = new ScrollMagic.Controller({
            globalSceneOptions: {
                triggerHook: '0',
            }
        })
        $split_text.each(function (i) {
            var $container = this;
            var parent = $container.closest('.text_image')
            var image = $(parent).find('figure');

            var split = new SplitText($split_text[i], {
                type: 'lines'
            });
            var tl = new TimelineMax();
            tl.staggerFrom(split.lines, 1, {
                y: '120%',
                ease: Power4.easeOut
            }, 0.1);
            tl.staggerFromTo(image, .5, { y: '10%', autoAlpha: 0 }, { y: '0%', autoAlpha: 1 })

            new ScrollMagic.Scene({
                triggerElement: this.closest('.text_image'),
            })
                .setClassToggle($container, "active")
                .setTween(tl)
                .addTo(controlup);
        });



    }
}
