/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, ScrollMagic) => {
        /*
        |
        | Constants
        |------------
        */
        const
            $header = $('#header'),
            $recetteImage = $('.recette-image'),
            $recetteText = $('.presentation-recette .col-text')
        ;


        /*
		|
		| initGoogleMap
		|----------------
		|
        */
        // if($(window).width() > 992){
        //     let controller = new ScrollMagic.Controller();
        //     const offset = $header.outerHeight() + 15

        //     var scene = new ScrollMagic.Scene({
        //             duration: $recetteText.height() - $recetteImage.height(),
        //             triggerHook: 'onLeave',
        //             offset: offset
        //         })
        //         .setPin($recetteImage)
        //         .addTo(controller);
        // }
        
    }
}
